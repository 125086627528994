// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/icons/tick.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/icons/tick.tsx");
  import.meta.hot.lastModified = "1726716723000.0098";
}
// REMIX HMR END

const Tick = ({
  fillColor = '#3BBA53',
  width = '33px',
  height = '32px'
}) => {
  return <svg width={width} height={height} viewBox="0 0 20 20" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2798 9.03082C13.4123 8.88865 13.4844 8.7006 13.481 8.5063C13.4776 8.312 13.3989 8.12661 13.2615 7.9892C13.1241 7.85179 12.9387 7.77308 12.7444 7.76965C12.5501 7.76622 12.362 7.83834 12.2198 7.97082L9.24985 10.9408L8.02985 9.72082C7.88767 9.58834 7.69963 9.51622 7.50532 9.51965C7.31102 9.52308 7.12564 9.60179 6.98822 9.7392C6.85081 9.87661 6.7721 10.062 6.76867 10.2563C6.76524 10.4506 6.83737 10.6386 6.96985 10.7808L8.71985 12.5308C8.86047 12.6713 9.0511 12.7502 9.24985 12.7502C9.4486 12.7502 9.63922 12.6713 9.77985 12.5308L13.2798 9.03082Z" fill={fillColor} />
      <path fillRule="evenodd" clipRule="evenodd" d="M17 10C17 10.9193 16.8189 11.8295 16.4672 12.6788C16.1154 13.5281 15.5998 14.2997 14.9497 14.9497C14.2997 15.5998 13.5281 16.1154 12.6788 16.4672C11.8295 16.8189 10.9193 17 10 17C9.08075 17 8.1705 16.8189 7.32122 16.4672C6.47194 16.1154 5.70026 15.5998 5.05025 14.9497C4.40024 14.2997 3.88463 13.5281 3.53284 12.6788C3.18106 11.8295 3 10.9193 3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3C11.8565 3 13.637 3.7375 14.9497 5.05025C16.2625 6.36301 17 8.14348 17 10ZM15.5 10C15.5 11.4587 14.9205 12.8576 13.8891 13.8891C12.8576 14.9205 11.4587 15.5 10 15.5C8.54131 15.5 7.14236 14.9205 6.11091 13.8891C5.07946 12.8576 4.5 11.4587 4.5 10C4.5 8.54131 5.07946 7.14236 6.11091 6.11091C7.14236 5.07946 8.54131 4.5 10 4.5C11.4587 4.5 12.8576 5.07946 13.8891 6.11091C14.9205 7.14236 15.5 8.54131 15.5 10Z" fill={fillColor} />
    </svg>;
};
_c = Tick;
export default Tick;
var _c;
$RefreshReg$(_c, "Tick");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;